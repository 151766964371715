import {Controller} from "stimulus"
import MicroModal from "micromodal"
import {maskInput} from "vanilla-text-mask"
import * as shared from "controllers-shared"

export default class extends Controller {

  static get targets() {
    return ["keydown",
            "success",
            "entry",
            "error",
            "errorcontent",
            "name",
            "email",
            "phone",
            "submit",
            "form"]
  }

  onModalClose() {
    shared.getActiveModalView("cta-modal", this.keydownTarget)
    for (var i = 0; i < this.formTarget.elements.length; i++) {
      if (!(this.formTarget.elements[i].id === "offer-id" || this.formTarget.elements[i].id === "sponsor-id")) {
        this.formTarget.elements[i].value = ""
      }
    }
    this.errorcontentTarget.innerHTML = ""
  }

  onSubmit() {
    shared.onSubmit(
      "cta-modal",
      this.formTarget,
      this.submitTarget,
      this.errorcontentTarget,
      this.errorTarget,
      this.successTarget,
      this.entryTarget,
      "I'm Interested"
    )
  }

  onSubmitKeydown(e) {
    shared.onSubmitKeydown(e, this.onSubmit)
  }

  connect() {
    MicroModal.init({
      disableScroll: true,
      onClose: () => this.onModalClose()
    });

    if (this.phoneTarget) {
      const phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
      maskInput({
        inputElement: this.phoneTarget,
        mask: phoneMask
      })
    }
  }
}
