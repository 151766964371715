import {Controller} from "stimulus"
import MicroModal from "micromodal"
import debounce from "lodash/debounce"
import * as builder from "wellness/builder"
import * as shared from "controllers-shared"

const recordSearch = (query, articleId) => {
  const subdomain = shared.getMetaContentByName("subdomain")
  const body = {
    search_query: query,
    selected_article_id: articleId,
    subdomain: subdomain
  }
  fetch("/api4/wellness/record-search", {
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  })
}

export default class extends Controller {
  static get targets() {
    return ["input", "content", "modal", "result", "status", "allResultsLink"]
  }

  highlightResult(resultIdx, direction) {
    const modalPos = this.contentTarget.getBoundingClientRect()
    this.resultIdx = resultIdx
    this.resultTargets.forEach((el, i) => {
      const resultPos = el.getBoundingClientRect()
      el.classList.toggle("result--current", resultIdx == i)
      if (direction === "down") {
        if (shared.hasClass(el, "result--current") && resultPos.bottom > modalPos.bottom) {
          this.contentTarget.scrollBy(0, resultPos.height)
        }
      } else if (direction === "up") {
        if (shared.hasClass(el, "result--current") && resultPos.top < modalPos.top) {
          this.contentTarget.scrollBy(0, -Math.abs(resultPos.height))
        }
      }
    })
  }

  next() {
    if (this.resultIdx < this.resultTargets.length - 1) {
      this.highlightResult(this.resultIdx + 1, "down")
    }
  }

  previous() {
    if (this.resultIdx > -1) {
      this.highlightResult(this.resultIdx - 1, "up")
    }
  }

  getSelected() {
    return this.resultTargets.filter((r) => shared.hasClass(r, "result--current"))[0]
  }

  cycleResults(e) {
    if (e.keyCode === 38) {
      this.previous()
    } else if (e.keyCode === 40 || e.keyCode === 9) {
      this.next()
    } else if (e.keyCode === 13) {
      const selected = this.getSelected()
      if (selected) window.location = selected.firstChild.href
    }
  }

  buildResult(resource) {
    const result = document.createElement("li")
    const resultLink = document.createElement("a")
    resultLink.classList.add("result-link")
    resultLink.href = resource.url + "?with-search=" + encodeURIComponent(this.inputTarget.value)
    const resultCard = document.createElement("div")
    const className = "card--list"
    resultCard.classList.add(className, "card--" + resource["resource-type"])
    resultCard.appendChild(builder.buildTag(resource))
    resultCard.appendChild(builder.buildHeroImage(resource))
    resultCard.appendChild(builder.buildText(resource, className))
    resultLink.appendChild(resultCard)
    result.appendChild(resultLink)
    result.setAttribute("data-target", "search.result")
    return result
  }

  buildList(data) {
    const content = document.createElement("ul")
    content.classList.add("results-list")
    data.map((resource) => {
      content.appendChild(this.buildResult(resource))
    })
    return content
  }

  addContent(data) {
    this.contentTarget.appendChild(this.buildList(data))
  }

  resetSearchBar() {
    this.resultIdx = -1
    this.contentTarget.innerHTML = ""
    this.contentTarget.scrollTo(0, 0)
    this.modalTarget.classList.remove("no-results")
    this.modalTarget.classList.remove("has-data")
  }

  // Takes a query, finds a list of potential matching words
  // and queries the server for a list of potential articles
  // that contain those words
  queryServer(query) {
    return fetch("/api4/wellness/search?q=" + encodeURIComponent(query) + "&subdomain=" + shared.getMetaContentByName("subdomain"))
      .then((response) => response.json())
      .then((data) => {
        this.resetSearchBar()
        if (data.length > 0) {
          this.modalTarget.classList.add("has-data")
          this.addContent(data)
          this.allResultsLinkTarget.textContent = `Show all results for "${query}"`
        } else {
          this.modalTarget.classList.add("no-results")
          this.contentTarget.textContent = `No results for "${query}"`
        }
      })
  }

  onSearch() {
    let query = this.inputTarget.value
    let queryLength = query.length
    const modalClasses = this.modalTarget.classList
    if (queryLength >= 3) {
      if (Weglot && Weglot.initialized && Weglot.getCurrentLang() !== "en") {
        Weglot.search(query, (data) => {
          this.queryServer(data)
        })
      } else {
        this.queryServer(query)
      }
    } else if (queryLength >= 1) {
      modalClasses.remove("has-data")
      modalClasses.add("no-results")
      this.contentTarget.textContent = "Searching..."
    } else {
      this.resetSearchBar()
    }
  }

  connect() {
    MicroModal.init({
      disableScroll: false,
      onClose: () => {
        if (this.inputTarget.value.length > 0) {
          recordSearch(this.inputTarget.value, "") // save search on clickaway (user searched but didn't choose an article)
        }
        this.resetSearchBar()
        this.inputTarget.value = ""
      }
    })
  }

  initialize() {
    this.onSearch = debounce(this.onSearch, 500)
  }
}
