import {Controller} from "stimulus"

export default class extends Controller {
  static get targets() {
    return ["imageContainer"]
  }

  connect() {
    this.load()
  }

  load() {
      fetch(`https://static-app-misc.teachbanzai.com/img/${this.element.getAttribute("data-svg-fetch-image")}`)
      .then(response => response.text())
      .then(html => {
        this.imageContainerTarget.innerHTML = html
      })
    }
}
