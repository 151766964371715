import {Controller} from "stimulus"
import * as shared from "controllers-shared"
import * as util from "wellness/util"
import * as builder from "wellness/builder"

const subdomain = shared.getMetaContentByName("subdomain")

export default class extends Controller {
  static get targets() {
    return ["topic", "content", "loadmore"]
  }

  connect() {
    let topicParam = this.getParam("topic")
    if (topicParam && topicParam !== "all") {
      this.topicTarget.value = topicParam
    } else {
      localStorage.removeItem("allArticles")
      localStorage.allArticles = this.contentTarget.innerHTML
      window.allPageParam = this.getParam("page") || "1"
    }
  }

  getParam(param) {
    const searchParams = new URLSearchParams(window.location.search)
    return searchParams.get(param)
  }

  setTopicParam(topic) {
    util.setParam("topic", topic)
  }

  setPageParam(page) {
    util.setParam("page", page || "1")
  }

  updateList(e) {
    let value = e.target.value
    this.setTopicParam(value)
    value === "all" ? this.setPageParam(window.allPageParam || "1") : this.setPageParam("1")
    this.getArticles(value)
  }

  buildCards(data) {
    const content = document.createElement("div")
    content.dataset.target = "pagination.resources"
    content.classList.add("flex", "flex-wrap", util.oddOrEven(data.length), util.gridLayout(data.length))
    const cards = data.map((resource) => content.appendChild(builder.buildCard(resource)))
    return content
  }

  addContent(data) {
    this.contentTarget.appendChild(this.buildCards(data))
  }

  buildLoadmore() {
    const loadmore = this.loadmoreTarget
    !document.querySelector(".load-more") && builder.buildLoadMore(loadmore)
  }

  getArticles(topic) {
    const loadmore = this.loadmoreTarget
    builder.buildLoadingView(this.contentTarget)
    const allArticles = localStorage.allArticles
    if (topic === "all") {
      if (allArticles) {
        this.contentTarget.innerHTML = ""
        this.contentTarget.innerHTML = allArticles
        this.buildLoadmore()
      } else {
        fetch(subdomain ? `/wellness/resources/articles/page?subdomain=${subdomain}&page=1` : `/wellness/resources/articles/page?page=1`)
          .then((response) => response.status === 200 && response.json())
          .then((data) => {
            const resources = data.resources
            this.buildLoadmore()
            return Object.keys(resources).map((i) => resources[i]) /* maps over map of resources */
          })
          .then((resources) => {
            this.contentTarget.innerHTML = ""
            this.addContent(resources)
          })
      }
    } else {
      fetch(subdomain ? `/wellness/articles/${topic}?subdomain=${subdomain}&page=1` : `/wellness/articles/${topic}?page=1`)
        .then((response) => response.status === 200 && response.json())
        .then((data) => {
          const resources = data.resources
          data["last-page"] ? builder.clearChildren(loadmore) : this.buildLoadmore()
          return Object.keys(resources).map((i) => resources[i]) /* maps over map of resources */
        })
        .then((resources) => {
          this.contentTarget.innerHTML = ""
          this.addContent(resources)
        })
    }
  }
}
