/* Builder is use to build elements using pure Javascript */

/* Card building tools */

const updateExternalLink = el => {
  el.setAttribute('target', '_blank')
  return el
}

/************************************************
 * Resource Card
 ************************************************/

/* Model html for a Resource Card */

// <div class="card--grid {{resource.layout}} card--{{resource.resource-type|name}}">
//   <a class="link--resource-image" href="{{resource.url}}"></a>
//   <div class="card__img" style="background: url({{resource.hero-image}}); background-position: center; background-size: cover;"></div>
//   <div class="card--grid__text">
//     <a class="link--resource-text" href="{{resource.url}}">
//       <div class="read-time color-1">{{resource.read-time}}</div>
//       <div class="card--grid__title">{{resource.title}}</div>
//       <div class="card--grid__desc" data-controller="truncate.js">{{resource.description}}</div>
//     </a>
//   </div>
// </div>

 export const buildCardLink = resource => {
   const link = document.createElement("a")
   link.classList.add("link--resource-image")
   link.href = resource.url
   return resource["is-external"] ? updateExternalLink(link) : link
 }

 const buildDoneBanner = () => {
   const doneBanner = document.createElement("div")
   doneBanner.innerHTML = "Done!"
   doneBanner.classList.add("rewards-corner-ribbon--done")
   return doneBanner
 }

 export const buildImage = (resource, grid = false) => {
   const heroImage = document.createElement("img")
   heroImage.src = resource["hero-image"]
   grid && heroImage.classList.add("card__img")
   return heroImage
 }

 export const buildHeroImage = resource => {
   const imageWrapper = document.createElement("div")
   imageWrapper.classList.add("card__img")
   imageWrapper.appendChild(buildImage(resource))
   // heroImage.style.backgroundImage = `url("${resource["hero-image"]}")`
   if (resource["is-complete-for-rewards"]) {
     imageWrapper.appendChild(buildDoneBanner())
   }
   return imageWrapper
 }

 export const buildTag = resource => {
   const type = resource["resource-type"]
   const tag = document.createElement("div")
   tag.classList.add("card__tag", "background-color-1", "bz-" + type, "bz")

   const tagText = document.createElement("span")
   tagText.appendChild(document.createTextNode(type))

   tag.appendChild(tagText)
   return tag
 }

 export const buildText = (resource, classname) => {
   const text = document.createElement("div")
   text.classList.add(`${classname}__text`)

   const time = document.createElement("div")
   time.classList.add("read-time", "color-1")
   time.appendChild(document.createTextNode(resource["read-time"]))

   const timeGroup = document.createElement("div")
   const rewardTag = document.createElement("span")
   const rewardTagContainer = document.createElement("span")
   const rewardSvg = document.createElement("img")
   rewardTag.classList.add("reward-tag")
   rewardTagContainer.classList.add("reward-tag-container")
   rewardSvg.src = "https://static-app-misc.teachbanzai.com/img/reward.svg"
   rewardTagContainer.appendChild(rewardSvg)
   rewardTag.appendChild(rewardTagContainer)
   timeGroup.classList.add("reward-read-time-group")
   timeGroup.append(rewardTag)
   timeGroup.appendChild(time)

   const title = document.createElement("div")
   title.classList.add(`${classname}__title`)
   title.appendChild(document.createTextNode(resource.title))

   const description = document.createElement("div")
   description.classList.add(`${classname}__desc`, "line-clamp", "line-clamp--3")
   description.appendChild(document.createTextNode(resource.description))

   if (resource["has-reward"] === true) {
     text.append(timeGroup)
   } else {
     text.append(time)
   }
   text.appendChild(title)
   text.appendChild(description)

   return text;
 }

// eslint-disable-next-line class-methods-use-this
export const buildCard = resource => {
  const card = document.createElement("div")
  const className = "card--grid"
  card.classList.add(className, "card--" + resource["resource-type"], resource.layout)
  card.appendChild(buildCardLink(resource))
  card.appendChild(buildImage(resource, true))
  card.appendChild(buildText(resource, className))

  return card
}

/************************************************
 * Loading Cards
 ************************************************/

const buildLoadingLabels = () => {
  const text = document.createElement("div")
  text.classList.add("card--loading__text")
  for (let i = 0; i < 3; i++) {
    const label = document.createElement("div")
    label.classList.add("loading__label")
    text.appendChild(label)
  }
  return text
}

/* Model html for a Loading card */

// <div class="card--loading">
//   <div class="loading__img"></div>
//   <div class="card--loading__text">
//     <div class="loading__label"></div>
//     <div class="loading__label"></div>
//     <div class="loading__label"></div>
//   </div>
// </div>

const buildLoadingCard = (className) => {
  const loadCard = document.createElement("div")
  loadCard.classList.add("card--loading", (className || "default"));

  const img = document.createElement("div")
  img.classList.add("loading__img")

  loadCard.appendChild(img)
  loadCard.appendChild(buildLoadingLabels())

  return loadCard
}

export const buildLoadView = (className) => {
  const loadView = document.createElement("div")
  loadView.classList.add("loading")
  for (let i = 0; i < 3; i++) {
    loadView.appendChild(buildLoadingCard(className))
  }
  return loadView;
}

/* Builds a loading view consisting of a big card and a small cards */
export const buildLoadingView = target => {
  target.innerHTML = ""
  target.appendChild(buildLoadView())
}

/* Builds a loading view consisting of 3 small cards */
export const buildLoadingView2 = target => {
  target.innerHTML = ""
  target.appendChild(buildLoadView("col-3"))
}

/* Build Load More section and Button */

/* Model html for a Load More Section */

// <div data-target="pagination.loadmore articles.loadmore">
//   <div className="load-more">
//     <button className="button" data-action="pagination#onLoadMore">Load More Content</button>
//   </div>
// </div>

export const clearChildren = target => target.innerHTML = ""

export const hasChildren = target => target.hasChildNodes()

export const buildLoadMore = (target, controller = "pagination") => {
  const button = document.createElement("button")
  button.classList.add("button")
  button.dataset.action = `${controller}#onLoadMore`
  button.textContent = "Load More"

  const loadMoreInner = document.createElement("div")
  loadMoreInner.classList.add("load-more")
  loadMoreInner.appendChild(button)

  target.appendChild(loadMoreInner)
}
