import {Controller} from "stimulus"
import * as shared from "controllers-shared"

export default class extends Controller {
  connect() {
    this.load()
  }

  load() {
    shared.fetchLogo("cta-logo-url", this.element)
  }

}
