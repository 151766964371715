import {Controller} from "stimulus"
import MicroModal from "micromodal"
import * as shared from "controllers-shared"
import * as validate from "shared/validate"

export default class extends Controller {

  static get targets() {
    return [
      "keydown",
      "emailError",
      "email",
      "name",
      "nameError",
      "passwordError",
      "password",
      "phoneNumber",
      "phoneError",
      "state",
      "stateError",
      "agree",
      "older",
      "submit",
      "loginForm",
      "loginEmail",
      "loginPassword",
      "phoneInput",
      "stateInput"
    ]
  }

  showPhone = JSON.parse(this.phoneInputTarget.getAttribute("data-value"))
  showState = JSON.parse(this.stateInputTarget.getAttribute("data-value"))

  initialize() {
    if (this.showPhone) {
      shared.removeClass(this.phoneInputTarget, "hidden-el")
    }
    if (this.showState) {
      shared.removeClass(this.stateInputTarget, "hidden-el")
    }
  }

  hasClickedSubmit = false

  errorTargets = [this.emailErrorTarget, this.passwordErrorTarget, this.phoneErrorTarget, this.stateErrorTarget, this.nameErrorTarget]

  showErrors() {
    this.errorTargets.map(t => shared.addClass(t,"show"))
  }
  hideErrors() {
    this.errorTargets.map(t => shared.removeClass(t,"show"))
  }

  onModalClose() {
    this.hideErrors()
    this.hasClickedSubmit = false

    this.emailErrorTarget.innerHTML = ""
    this.passwordErrorTarget.innerHTML = ""
    this.phoneErrorTarget.innerHTML = ""
    this.stateErrorTarget.innerHTML = ""
    this.nameErrorTarget.innerHTML = ""
    this.nameTarget.value = ""
    this.emailTarget.value = ""
    this.passwordTarget.value = ""
    this.phoneNumberTarget.innerHTML = ""
    this.phoneNumberTarget.value = ""
    this.stateTarget.value = ""
    this.agreeTarget.checked = false
    this.olderTarget.checked = false
  }

  hasErrors(base, phone, state) {
    if (this.showPhone && this.showState) {
      return base || phone || state
    } else if (this.showPhone) {
      return base || phone
    } else if (this.showState) {
      return base || state
    } else {
      return base
    }
  }

  hasInvalidData() {
    const baseChecks = (
      this.emailErrorTarget.innerHTML.length > 0 ||
      this.passwordErrorTarget.innerHTML.length > 0 ||
      this.nameErrorTarget.innerHTML.length > 0 ||
      this.stateErrorTarget.innerHTML.length > 0
    )

    return this.hasErrors(baseChecks, this.phoneErrorTarget.innerHTML.length > 0, this.stateErrorTarget.innerHTML.length > 0)
  }

  hasEmptyFields() {
    const baseChecks = (
      this.emailTarget.value == "" ||
      this.passwordTarget.value == "" ||
      this.nameTarget.value == "" ||
      !this.olderTarget.checked ||
      !this.agreeTarget.checked
    )

    return this.hasErrors(baseChecks, this.phoneNumberTarget.value == "", this.stateTarget.value == "")
  }

  disableSubmit() {
    shared.addClass(this.submitTarget, "disabled")
  }

  enableSubmit() {
    shared.removeClass(this.submitTarget, "disabled")
  }

  updateSubmitButton() {
    if (this.hasClickedSubmit && this.hasInvalidData()) {
      this.disableSubmit()
    } else if (this.hasEmptyFields()) {
      this.disableSubmit()
    } else {
      this.enableSubmit()
    }
  }

  onNameChange() {
    const name = this.nameTarget.value.trim()
    if (name && name.length) {
      this.nameErrorTarget.innerHTML = ""
      this.updateSubmitButton()
    } else {
      this.nameErrorTarget.innerHTML = "Please enter your name."
      this.updateSubmitButton()
    }
  }

  onEmailChange() {
    const email = this.emailTarget.value.substr(0, validate.Constants.EMAIL_LENGTH).toLowerCase()
    if (email == "") {
      this.emailErrorTarget.innerHTML = ""
    } else if (validate.isEmailValid(email)) {
      fetch(`/api/users/username/validate?username=${encodeURIComponent(email)}`, {
        method: "get"
      })
      .then(response => response.status === 200 && response.json())
      .then (data => {
        if (data == true) {
          this.emailErrorTarget.innerHTML = ""
          this.updateSubmitButton()
        } else {
          this.emailErrorTarget.innerHTML = email + " is already being used."
          this.updateSubmitButton()
        }
      })
      .catch(() => this.emailTarget.innerHTML("Something went wrong. Please try to validate your email again."))
    } else {
      this.emailErrorTarget.innerHTML = email + " is an invalid email address."
      this.updateSubmitButton()
    }
  }

  onPasswordChange() {
    const p = {password: this.passwordTarget.value}
    if (validate.isPasswordTooShort(p)) {
      this.passwordErrorTarget.innerHTML = "Your password must be at least 5 characters long."
    } else if (validate.isInvalidPassword(p)) {
      this.passwordErrorTarget.innerHTML = "Passwords can only contain letters, numbers, or symbols—no spaces, etc."
    } else {
      this.passwordErrorTarget.innerHTML = ""
    }
    this.updateSubmitButton()
  }

  cleanedPhoneNumber () {
    return this.phoneNumberTarget.value.trim().replace(/[^0-9]/g, "")
  }

  onPhoneChange() {
    const phoneNumber = this.cleanedPhoneNumber()
    const phoneErrorTarget = this.phoneErrorTarget

    if (phoneNumber == "") {
      phoneErrorTarget.innerHTML = "Please enter your phone number."
    } else if (!validate.isPhoneNumberValid(phoneNumber)) {
      phoneErrorTarget.innerHTML = "Please enter a valid phone number."
    } else {
      phoneErrorTarget.innerHTML = ""
    }
    this.updateSubmitButton()
  }

  onStateChange() {
    // NOTE: this is not *state* state, this is the state of residence for the user
    const error = this.stateErrorTarget

    if (this.stateTarget.value === "") {
      shared.removeClass(this.stateTarget, "selected")
      error.innerHTML = "Please select your state."
    } else {
      shared.addClass(this.stateTarget, "selected")
      error.innerHTML = ""
    }
    this.updateSubmitButton()
  }

  onSubmit() {
    if (this.hasEmptyFields() || this.hasInvalidData()) {
      this.hasClickedSubmit = true
      this.showErrors()
      this.updateSubmitButton()
    } else {
      this.loginEmailTarget.value = this.emailTarget.value
      this.loginPasswordTarget.value = this.passwordTarget.value
      fetch("/api4/direct/signup", {
        method: "post",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user: {
            username: this.emailTarget.value,
            password: this.passwordTarget.value,
            birthdate: "02-11-1820",
            name: this.nameTarget.value,
            language: "en",
            first_name: "",
            last_name: "",
            state_code: this.stateTarget.value,
            phone_number: this.cleanedPhoneNumber()
          },
          subdomain: shared.getMetaContentByName("subdomain")
        })
      })
      .then(response => {
        switch (response.status) {
          case 200:
            this.submitTarget.innerHTML = "Submitting..."
            this.loginFormTarget.submit()
            break
          case 422:
            if (response.body.error === "not-unique") {
              this.emailErrorTarget.innerHTML = this.emailTarget.value + "is already being used."
              this.submitTarget.innerHTML = "Sign up"
              this.updateSubmitButton()
            } else {
              this.emailErrorTarget.innerHTML = "Whoops, something happened. Please refresh and try again."
              this.submitTarget.innerHTML = "Sign up"
              this.updateSubmitButton()
              throw new Error("Error on direct signup for user with 422.")
            }
            break
          default:
            this.emailErrorTarget.innerHTML = "Whoops, something happened. Please refresh and try again."
            this.submitTarget.innerHTML = "Sign up"
            this.updateSubmitButton()
            throw new Error("Error on signup for user " + this.emailTarget.value + ": (" + response.statusCode + ") " + response.statusText)
        }
      })
      .catch(err => {
        throw new Error("Unknown error on completing direct signup:" + err)
      })
    }
  }

  onSubmitKeydown(e) {
    if (e.keyCode === 13) {
      this.onSubmit()
      e.preventDefault()
      e.stopPropagation()
    }
  }

  connect() {
    MicroModal.init({
      disableScroll: true,
      onClose: () => this.onModalClose()
    })
  }
}
