import {Controller} from "stimulus"
import * as shared from "controllers-shared"
import rewardBadge from "images/reward-badge.svg?raw"
import graySquiggle from "images/squiggle-gray.svg?raw"
import rewardSvg from "images/reward.svg?raw"

const buildDotGraph = (reward) => {
  const container = document.createElement("div")
  const total = reward["wc.reward/total-dots"]
  const progress = reward["wc.reward/progress"]
  total.map((r, idx) => {
    const dot = document.createElement("div")
    const line = document.createElement("div")

    dot.classList.add("segment--circle", `count-${total.length}`)
    line.classList.add("segment--line", `count-${total.length}`)

    if (idx < progress.length) {
      dot.classList.add("!bg-primary-1")
    }
    if (idx < progress.length - 1) {
      line.classList.add("!bg-primary-1")
    }

    container.appendChild(dot)

    if (idx !== total.length - 1) {
      container.appendChild(line)
    }
  })
  container.classList.add("reward__segments")
  return container
}

const getPercent = (num1, num2) => `${Math.floor((num1 / num2) * 100)}%`

export default class extends Controller {
  static get targets() {
    return ["completeBox", "submit"]
  }

  buildCompletedListItem(reward) {
    const item = document.createElement("li")
    const h4 = document.createElement("h4")
    const rewardText = document.createElement("div")
    const rewardName = document.createElement("a")
    const badgeText = document.createElement("span")
    const nameGroup = document.createElement("div")
    const badgeContainer = document.createElement("div")

    badgeText.innerHTML = "Done"
    badgeText.classList.add("label")
    badgeContainer.innerHTML = rewardBadge
    badgeContainer.appendChild(badgeText)
    badgeContainer.classList.add("reward__badge", "complete")
    h4.innerHTML = "You earned a reward"
    rewardText.innerHTML = "You did it! " + shared.getMetaContentByName("sponsor-name") + " will be in touch soon."
    rewardText.classList.add("reward-text")
    nameGroup.classList.add("name-group")
    nameGroup.innerHTML = rewardSvg
    // rewardName.innerHTML = reward["wc.reward/title"] + " — " + reward["wc.reward/completion-percent"]
    rewardName.innerHTML = reward["wc.reward/title"] + " — " + getPercent(reward["wc.reward/filled-dots"], reward["wc.reward/total-dots"].length)
    rewardName.href = reward["wc.reward/url"]
    rewardName.classList.add("name")
    nameGroup.appendChild(rewardName)
    item.appendChild(badgeContainer)
    item.appendChild(h4)
    item.appendChild(rewardText)
    item.appendChild(nameGroup)
    return item
  }

  buildIncompleteListItem(reward) {
    const item = document.createElement("li")
    const nameGroup = document.createElement("div")
    const rewardName = document.createElement("a")
    rewardName.href = reward["wc.reward/url"]
    rewardName.classList.add("name")
    rewardName.innerHTML = reward["wc.reward/title"] + " — " + getPercent(reward["wc.reward/filled-dots"], reward["wc.reward/total-dots"].length)
    nameGroup.classList.add("name-group")
    nameGroup.innerHTML = rewardSvg
    nameGroup.appendChild(rewardName)
    item.appendChild(nameGroup)
    item.appendChild(buildDotGraph(reward))
    return item
  }

  buildCompletedList(rewards) {
    if (rewards.length > 0) {
      const list = document.createElement("ul")
      const squiggle = document.createElement("div")
      squiggle.classList.add("separator__squiggle")
      squiggle.innerHTML = graySquiggle
      rewards.map((reward, idx) => {
        list.appendChild(this.buildCompletedListItem(reward))
        if (idx !== rewards.length - 1) {
          list.appendChild(squiggle)
        }
      })
      return list
    }
  }

  buildIncompleteList(rewards) {
    if (rewards.length > 0) {
      const list = document.createElement("ul")
      rewards.map((reward) => list.appendChild(this.buildIncompleteListItem(reward)))
      return list
    }
  }

  addContent({complete, incomplete}) {
    const h3 = document.createElement("h3")
    const incompleteDescription = document.createElement("div")
    const squiggle = document.createElement("div")
    squiggle.classList.add("separator__squiggle")
    squiggle.innerHTML = graySquiggle
    incompleteDescription.classList.add("reward-text")
    incompleteDescription.innerHTML =
      incomplete && incomplete.length > 1
        ? "Nice work! Click the links below to continue making progress toward your rewards."
        : "Nice work! Click the link below to continue making progress toward your rewards."
    h3.innerHTML = "Completed!"
    this.completeBoxTarget.appendChild(h3)
    if (complete && complete.length > 0) {
      this.completeBoxTarget.appendChild(this.buildCompletedList(complete))
    }
    if (complete && complete.length > 0 && incomplete && incomplete.length > 0) {
      this.completeBoxTarget.appendChild(squiggle)
    }
    if (incomplete && incomplete.length > 0) {
      this.completeBoxTarget.appendChild(incompleteDescription)
      this.completeBoxTarget.appendChild(this.buildIncompleteList(incomplete))
    }
  }

  markComplete() {
    this.submitTarget.innerHTML = "Saving..."
    const resourceId = shared.getMetaContentByName("article-id")
    fetch(`/api4/wellness/rewards/${resourceId}`, {
      method: "post"
    })
      .then((response) => response.status === 200 && response.json())
      .then((data) => {
        this.completeBoxTarget.innerHTML = ""
        this.completeBoxTarget.id = "completed"
        this.addContent(data)
      })
  }
}
