/* Util functions to support The Financial Wellness Center */

export const toArray = list => (
  [].slice.call(list)
)

export const oddOrEven = num => {
  return ( num % 2 == 1 ) ? "odd" : "even";
}

export const gridLayout = count => {
  if (count % 3 == 0) {
    return "plus-3"
  } else if ((count - 5) % 3 == 0) {
    return "plus-5"
  } else if ((count - 7) % 3 == 0) {
    return "plus-7"
  }
}

export const getMetaContentByName = name => {
  const sel = document.querySelector("meta[name='" + name + "']")
  return sel ? sel.getAttribute("content") : null
}

export const setParam = (param, value) => {
  if ('URLSearchParams' in window) {
    var searchParams = new URLSearchParams(window.location.search)
    searchParams.set(param, value);
    var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
    history.pushState(null, '', newRelativePathQuery);
  }
}

export const getParam = (param) => {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.get(param)
}
