import {Controller} from "stimulus"
import * as shared from "controllers-shared"
import * as builder from "wellness/builder"
import * as util from "../util"

/* Model html for a card--list */

//   <a className={`card--list card--${resource["resource-type"]}`}>
//     <div className={`card__tag bz bz-${resource["resource-type"]} background-color-1 color-4`}></div>
//     <img src={resource["hero-image"]} />
//     <div className="card--list__text">
//       <div className="read-time color-1">{resource["read-time"]}</div>
//       <div className="card--list__title">{resource.title}</div>
//       <div className="card--list__desc">{resource.description}</div>
//     </div>
//   </a>

const subdomain = shared.getMetaContentByName("subdomain")

export default class extends Controller {

  static get targets() {
  	return ["topic", "content", "resources", "loading", "loadmore"]
  }

  initialize() {
    this.page = 1
  }

  connect() {
    this.topicId = "saving"
    document.getElementById(this.topicId).classList.add("selected")
  }

  nextPage() {
    return this.page += 1
  }

  updateList(e) {
    const current = e.currentTarget
    const firstTarget = this.topicTarget
    const id = current === firstTarget ? firstTarget.value : current.id
    this.topicId = id
    this.topicTargets.map(t => (
      t.id === id ? t.classList.add("selected") : t.classList.remove("selected")
    ))
    this.page = 1
    this.getTopicList(id)
  }

  updateListWithKeyboard(e) {
    if (event.keyCode == 13) {
      this.updateList(e)
    }
  }

  buildCard(resource) {
    const card = document.createElement("a")
    const className = "card--list"
    card.classList.add(className, "card--" + resource["resource-type"])
    card.href = resource.url
    card.appendChild(builder.buildTag(resource))
    card.appendChild(builder.buildHeroImage(resource))
    card.appendChild(builder.buildText(resource, className))
    return card
  }

  buildCards(data) {
    const content = document.createElement("div")
    content.dataset.target = "popular.resources"
    data.map(resource => content.appendChild(this.buildCard(resource)))
    return content
  }

  addContent(data) {
    this.contentTarget.appendChild(this.buildCards(data))
  }

  buildLoadmore() {
    const loadmore = this.loadmoreTarget
    !builder.hasChildren(loadmore) && builder.buildLoadMore(loadmore, "popular")
  }

  getTopicList(id) {
    const content = this.contentTarget
    const loadmore = this.loadmoreTarget
    builder.buildLoadingView(content)
    fetch(subdomain ? `/wellness/topic-resources/${id}?subdomain=${subdomain}&_limit=2&page=1&popular=true` : `/wellness/topic-resources/${id}?_limit=2&page=1&popular=true`)
    .then(response => (
      response.status === 200 && response.json()
    ))
    .then(data => {
      const resources = data.resources
      data["last-page"] ? builder.clearChildren(loadmore) : this.buildLoadmore()
      return Object.keys(resources).map(i => resources[i]) /* maps over map of resources */
    })
    .then(data => {
      builder.clearChildren(content)
      document.getElementById("popular-anchor").scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
      this.addContent(data)
    })
  }

  getResources(page) {
    const loading = this.loadingTarget
    builder.buildLoadingView(loading)
    const topicId = this.topicId
    fetch(subdomain ? `/wellness/topic-resources/${topicId}?subdomain=${subdomain}&_limit=2&page=${page}&popular=true` : `/wellness/topic-resources/${topicId}?_limit=2&page=${page}&popular=true`)
      .then(response => (
        response.status === 200 && response.json()
      ))
      .then(data => {
        const resources = data.resources
        data["last-page"] && builder.clearChildren(this.loadmoreTarget)
        return Object.keys(resources).map(i => resources[i]) /* maps over map of resources */
      })
      .then(resources => {
        builder.clearChildren(loading)
        resources.map(resource => this.resourcesTarget.appendChild(this.buildCard(resource)))
      })
  }

  onLoadMore() {
    this.getResources(this.nextPage())
  }
}