import {Controller} from "stimulus"
import * as shared from "controllers-shared"

export default class extends Controller {

  static get targets() {
    return ["arch"]
  }

  connect() {
    if (document.getElementById("footer-cta")) {
      shared.addClass(this.archTarget, "bg-secondary-1")
    } else {
      shared.removeClass(this.archTarget, "bg-secondary-1")
    }
  }
}
