import {Controller} from "stimulus"
import {truncate} from "util/truncate"

export default class extends Controller {

  connect() {
    const newText = truncate(this.element.textContent, parseInt(this.element.getAttribute("data-truncate-limit")))
    this.element.textContent = newText
  }

}
