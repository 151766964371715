import { Controller } from "stimulus";
import * as shared from "controllers-shared";

const authenticated = shared.getMetaContentByName("authenticated") === "true";

export default class extends Controller {
  static get targets() {
    return ["link"];
  }

  connect() {
    this.linkTargets.forEach(el => {
      if (authenticated) {
        el.setAttribute("href", el.getAttribute("data"))
      }
    });
  }
}
