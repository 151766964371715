import {Controller} from "stimulus"
import * as shared from "controllers-shared"

export default class extends Controller {
  static get targets() {
    return ["menu"]
  }

  initialize() {
    this.close()
  }

  // getHeightOfMenu() {
  //   const height = this.menuTarget.offsetHeight
  //   document.getElementById("search-modal-container").style.marginTop = height + 24 + "px"
  // }

  open() {
    shared.showMenu(this.menuTargets, true)
  }

  close() {
    shared.showMenu(this.menuTargets, false)
  }

}
