import {Controller} from "stimulus"

export default class extends Controller {

  static get targets() {
  	return ["twitter", "email"]
  }

  connect() {
    this.twitterTarget.href = "https://twitter.com/intent/tweet?url=" + window.location.href
    this.emailTarget.href = "mailto:?body=" + window.location.href

    // Facebook init function
    window.fbAsyncInit = function() {
      FB.init({
        appId            : 2141485292749210,
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v10.0'
      });
    };
  (function(d, s, id){
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {return;}
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));

    if (document.getElementById("shareBtn")) {
      document.getElementById("shareBtn").onclick = function() {
        FB.ui({
          method: 'share',
          href: window.location.href,
          display: 'popup',
        }, function(response) {});
      }
    }

    // Twitter init function
    window.twttr = (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0],
      t = window.twttr || {};
      if (d.getElementById(id)) return t;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://platform.twitter.com/widgets.js";
      fjs.parentNode.insertBefore(js, fjs);
      t._e = [];
      t.ready = function(f) {
        t._e.push(f);
      }
      return t;
    }(document, "script", "twitter-wjs"));
  }

}
