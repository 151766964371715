import {Controller} from "stimulus"

export default class extends Controller {

  static get targets() {
    return ["toggle"]
  }

  click() {
    this.toggleNav()
  }

  toggleNav() {
    this.toggleTargets.forEach(el => {
      el.classList.toggle("show")
    })
  }


}
